import {observer} from 'mobx-react-lite';
import {ReactFlowProvider} from '@xyflow/react';
import {FC, useEffect, useState} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Box, Center, Flex, IconButton, Tab, TabList, Tabs} from '@chakra-ui/react';
import {i18n, useT, EStructureItem} from '@progress-fe/core';
import {Svg} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {RFWorkZone, RFGraphZone, RFResultsZone} from 'ui-kit';

interface IProps {
  isExpanded: boolean;
  toggleIsExpanded: () => void;
}

const TABS: {index: number; name: string; type: EStructureItem}[] = [
  {index: 0, name: i18n.t('rfZones.tabs.structure'), type: EStructureItem.Settings},
  {index: 1, name: i18n.t('rfZones.tabs.calculation'), type: EStructureItem.Task},
  {index: 2, name: i18n.t('rfZones.tabs.results'), type: EStructureItem.Result}
];

const MIN_RF_WIDTH_PX = 470;
const MIN_RF_MESSAGE_WIDTH_PX = 160;

const ProjectZonesFC: FC<IProps> = ({isExpanded, toggleIsExpanded}) => {
  const {techProcessStore, catalogsStore} = useStore();
  const {uiState, projectElements, projectResults, projectTask} = techProcessStore;
  const {selectedElement, selectedSubElement, nodeList, edgeList} = techProcessStore;
  const {workzoneKey, isSubWorkzone, backToEntity} = techProcessStore;
  const {subWorkzoneElement} = projectElements;
  const {graphzoneLastUpdate} = projectTask;
  const {elementsCatalog} = catalogsStore;
  const {entityId, entityType} = uiState;

  const [tabIndex, setTabIndex] = useState(0);

  const {t} = useT();

  useEffect(() => {
    const activeTab = TABS.find((tab) => tab.type === entityType) || TABS[0];
    if (activeTab.index !== tabIndex) {
      setTabIndex(activeTab.index);
    }
  }, [entityType, tabIndex]);

  const handleTabChange = (index: number) => {
    const activeTab = TABS[index] || TABS[0];
    techProcessStore.selectEntityByType(activeTab.type);
  };

  return (
    <Box
      h="100%"
      bg="darkWhite"
      position={isExpanded ? 'absolute' : 'relative'}
      {...(isExpanded ? {top: 0, bottom: 0, left: '-48px', right: 0, zIndex: 1} : {})}
    >
      <AutoSizer disableWidth={false}>
        {({height, width}) => {
          if (width < MIN_RF_WIDTH_PX) {
            return (
              <Center
                bg="darkWhite"
                width={`${width}px`}
                height={`${height}px`}
                flexDirection="column"
              >
                {width >= MIN_RF_MESSAGE_WIDTH_PX && (
                  <Box p="0 8px" textAlign="center">
                    <Box fontWeight={700}>{t('rfZones.common.noSpace')}</Box>
                    <Box>{t('rfZones.common.changeWidth')}</Box>
                  </Box>
                )}
              </Center>
            );
          }

          return (
            <Box width={width}>
              {isSubWorkzone ? (
                <Box top="12px" left="62px" position="absolute" borderRadius="4px" zIndex={1}>
                  <Flex gap="12px" alignItems="center">
                    <IconButton
                      size="sm"
                      aria-label=""
                      variant="ghostBg"
                      icon={<Svg name="Back" />}
                      sx={{boxShadow: 'shadow_2'}}
                      onClick={() => backToEntity(subWorkzoneElement?.uuid)}
                    />
                    <Box fontSize="15px" fontWeight={700}>
                      {subWorkzoneElement?.name}
                    </Box>
                  </Flex>
                </Box>
              ) : (
                <Tabs variant="solid-rounded" index={tabIndex} onChange={handleTabChange}>
                  <Flex align="center" justify="center" p="12px 0 0 48px">
                    <TabList position="relative" zIndex={1}>
                      {TABS.map((tab) => (
                        <Tab key={tab.index}>{tab.name}</Tab>
                      ))}
                    </TabList>
                  </Flex>
                </Tabs>
              )}

              <Box top="12px" right="12px" position="absolute" borderRadius="4px" zIndex={1}>
                <IconButton
                  size="sm"
                  aria-label=""
                  variant="ghostBg"
                  onClick={toggleIsExpanded}
                  sx={{boxShadow: 'shadow_2'}}
                  icon={<Svg name={isExpanded ? 'Collapse' : 'Expand'} />}
                />
              </Box>

              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                {/* PROJECT WORKZONE */}
                {tabIndex === 0 && (
                  <ReactFlowProvider>
                    <RFWorkZone
                      width={width}
                      height={height}
                      key={workzoneKey}
                      initialNodes={nodeList}
                      initialEdges={edgeList}
                      isSubWorkzone={isSubWorkzone}
                      parentNodeId={projectElements.subWorkzoneUuid}
                      menuItems={elementsCatalog.rfMenuItems}
                      selectedNodeId={selectedElement?.uuid || null}
                      selectedSubNodeId={selectedSubElement?.uuid || null}
                      onCreateElement={techProcessStore.createElement}
                      onDeleteElement={techProcessStore.deleteElement}
                      onConnectElements={techProcessStore.connectElements}
                      onDisconnectElements={techProcessStore.disconnectElements}
                      onChangeNodePosition={projectElements.updateNodePosition}
                      onChangeSubNodePosition={projectElements.updateSubNodePosition}
                    />
                  </ReactFlowProvider>
                )}

                {/* PROJECT GRAPHZONE */}
                {tabIndex === 1 && (
                  <RFGraphZone
                    key={graphzoneLastUpdate.toString()}
                    width={width}
                    height={height}
                    initialNodes={projectTask.nodeList}
                    initialEdges={projectTask.edgeList}
                  />
                )}

                {/* PROJECT RESULTS */}
                {tabIndex === 2 && (
                  <RFResultsZone
                    height={height}
                    selectedEntityId={entityId}
                    resultsDetails={projectResults.results}
                  />
                )}
              </Box>
            </Box>
          );
        }}
      </AutoSizer>
    </Box>
  );
};

export const ProjectZones = observer(ProjectZonesFC);
