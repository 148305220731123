import {Edge, Node} from '@xyflow/react';
import {ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const ELEMENTS_SAMPLE_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    nodes: [
      {
        id: '196C89E0-1111-0000-0000-000000000006', // CS-100
        type: ERFElement.ComponentSplitter,
        data: {
          elementName: 'CS-100',
          templateCode: 'default'
        },
        position: {x: 0, y: 0},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000007', // A-100
        type: ERFElement.Absorber,
        data: {
          elementName: 'A-100',
          templateCode: 'default'
        },
        position: {x: 150, y: 0},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000008', // CR-100-1
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-1',
          templateCode: 'fixedbed-template-1'
        },
        position: {x: 0, y: 350},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000009', // CR-100-2
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-2',
          templateCode: 'fixedbed-template-2'
        },
        position: {x: 250, y: 350},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000010', // CR-100-3
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-3',
          templateCode: 'fixedbed-template-3'
        },
        position: {x: 500, y: 350},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000011', // CR-100-4
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-4',
          templateCode: 'fixedbed-template-4'
        },
        position: {x: 0, y: 580},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000012', // CR-100-5
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-5',
          templateCode: 'fixedbed-template-5'
        },
        position: {x: 250, y: 580},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000013', // CR-100-6
        type: ERFElement.CatReforming,
        data: {
          elementName: 'CR-100-6',
          templateCode: 'fixedbed-template-6'
        },
        position: {x: 500, y: 580},
        connectable: true,
        deletable: true
      }
    ],
    edges: []
  }
];
