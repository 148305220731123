import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box} from '@chakra-ui/react';
import {ISimpleTableColumnTarget, ISimpleTableRowTarget, SimpleTable} from '@progress-fe/ui-kit';

import {
  TCalculationResultItemModel,
  TCalculationTableResultModel
} from '../../../../../core/models';

interface IProps {
  resultsInstance: TCalculationResultItemModel;
}

const TableResultsFC: FC<IProps> = ({resultsInstance}) => {
  const initialData = resultsInstance.data as TCalculationTableResultModel;

  const columns: ISimpleTableColumnTarget[] = useMemo(() => {
    return initialData.columns.map((c, index) => ({
      name: c.title,
      columnTemplate: index === 0 ? '2fr' : '1fr',
      align: index === 0 ? 'left' : 'right',
      propertyName: 'col_' + index
    }));
  }, [initialData.columns]);

  const rows: ISimpleTableRowTarget[] = useMemo(() => {
    const rowsCount = initialData.columns[0]?.values.length || 0;
    if (rowsCount === 0) return [];

    const rowsList: ISimpleTableRowTarget[] = [];
    for (let i = 0; i < rowsCount; i++) {
      rowsList.push({
        value: initialData.columns.reduce<Record<string, string>>((acc, cur, idx) => {
          const colProperty = columns[idx]?.propertyName;
          if (!colProperty) {
            return acc;
          }
          acc[colProperty] = cur.values[idx]?.value?.toString() || '';
          return acc;
        }, {})
      });
    }

    return rowsList;
  }, [columns, initialData.columns]);

  return (
    <Box padding="10px 0 0 0">
      <SimpleTable columns={columns} rows={rows} />
    </Box>
  );
};

export const TableResults = observer(TableResultsFC);
