import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ITab, JsForm} from '@progress-fe/ui-kit';
import {Tabs, TabList, Tab, TabPanels, TabPanel} from '@chakra-ui/react';

import {useStore} from 'core/hooks';
import {TJsonSchemaModel} from 'core/models';

export interface IJsonSchemaTab extends ITab {
  jsonSchema: TJsonSchemaModel;
}

interface IProps<T> {
  tabs: IJsonSchemaTab[];
  onChange: (formData: T, tabIdx: number, changedFieldPath?: string) => void;
}

const JsTabsFormFC = <T,>({tabs, onChange}: IProps<T>) => {
  const {projectStore} = useStore();

  const [tabIndex, setTabIndex] = useState(0);

  const withTabs = useMemo(() => tabs.length > 1, [tabs.length]);

  useEffect(() => {
    setTabIndex(projectStore?.uiState?.tabIndex || 0);
  }, [projectStore?.uiState?.tabIndex]);

  useEffect(() => {
    if (tabs.length > 0 && tabIndex > tabs.length - 1) {
      setTabIndex(0);
      projectStore?.selectTabIndex(0);
    }
  }, [tabIndex, tabs.length, projectStore]);

  return (
    <Tabs
      index={tabIndex}
      variant="soft-rounded"
      onChange={(index) => {
        setTabIndex(index);
        projectStore?.selectTabIndex(index);
      }}
    >
      {withTabs && (
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.index} isDisabled={tab.isDisabled}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>
        {tabs.map(({jsonSchema}, index) => (
          <TabPanel key={`${index}`}>
            <JsForm
              schema={jsonSchema.schema}
              uiSchema={jsonSchema.uiSchema}
              formData={jsonSchema.formData as T}
              onChange={(formData, changedFieldPath) => {
                onChange(formData, index, changedFieldPath);
              }}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const JsTabsForm = observer(JsTabsFormFC);
